import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconCollapse = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M17.675 13.115 12 7.44l-5.675 5.675a1.249 1.249 0 0 0 .885 2.13h9.585c.505 0 .96-.305 1.155-.77a1.24 1.24 0 0 0-.27-1.36z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconCollapse;
