import * as React from 'react';
import type { DuotoneIconBaseProps } from '../../types';
const DuotoneNeoIconCall_cancel = ({
	size = 24,
	...props
}: DuotoneIconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill={props.color2}
				fillRule="evenodd"
				d="M10.336 6.811a.5.5 0 0 1-.13.483L7.9 9.6a15.1 15.1 0 0 0 3.287 4.273L8.71 16.35A18.46 18.46 0 0 1 2.857 4.747C2.739 3.649 3.645 2.75 4.75 2.75h4.116a.5.5 0 0 1 .483.37zm-.48 10.514a18.4 18.4 0 0 0 9.397 3.818c1.098.118 1.997-.788 1.997-1.893v-4.116a.5.5 0 0 0-.37-.483l-3.691-.986a.5.5 0 0 0-.483.129L14.4 16.1a15 15 0 0 1-2.047-1.272z"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.665 12.275 20.72 2.22l1.06 1.06-18.505 18.5-1.06-1.06 4.88-4.88A19.2 19.2 0 0 1 2.13 5.04c-.095-.76.15-1.53.665-2.11.525-.59 1.28-.93 2.07-.93H8.86c.565 0 1.065.38 1.21.925l.99 3.69c.115.43-.01.895-.325 1.21l-1.91 1.91c.515.91 1.14 1.76 1.84 2.54m-6.74-8.35c-.235.265-.34.6-.3.94L3.62 4.86a17.7 17.7 0 0 0 4.54 9.915l1.445-1.445a15.8 15.8 0 0 1-2.37-3.39l-.25-.485 2.59-2.59-.9-3.365H4.87c-.36 0-.705.155-.945.425m13.46 9.01 3.69.99h-.005c.545.15.925.645.925 1.21v3.995c0 .79-.34 1.545-.93 2.07a2.7 2.7 0 0 1-2.11.665 19.2 19.2 0 0 1-9.655-3.99l1.07-1.07a17.7 17.7 0 0 0 8.76 3.57c.345.035.675-.065.94-.3.27-.24.425-.585.425-.945v-3.805l-3.365-.9-2.59 2.59-.485-.25c-.785-.405-1.53-.88-2.235-1.41l1.075-1.075c.44.32.895.62 1.37.89l1.91-1.91c.315-.315.78-.44 1.21-.325"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default DuotoneNeoIconCall_cancel;
