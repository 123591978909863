import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconUser_settings = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.5 3.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8M5 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0m-.57 10.91-1.016 3.784-1.448-.388L2.98 18.02A4.75 4.75 0 0 1 7.57 14.5H11V16H7.57c-1.47 0-2.759.987-3.14 2.41m11.32-.91a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0m-1.413.75H13v-1.5h1.337c.081-.343.216-.665.396-.956l-.943-.944 1.06-1.06.944.943c.291-.18.613-.315.956-.396V13h1.5v1.337c.343.081.665.216.956.396l.944-.943 1.06 1.06-.943.944c.18.291.315.613.396.956H22v1.5h-1.337a3.2 3.2 0 0 1-.396.956l.943.944-1.06 1.06-.944-.943c-.291.18-.613.315-.956.396V22h-1.5v-1.337a3.2 3.2 0 0 1-.956-.396l-.944.943-1.06-1.06.943-.944a3.2 3.2 0 0 1-.396-.956"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconUser_settings;
