import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconIncoming_forwarding = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4.75 2C3.255 2 1.94 3.232 2.11 4.827c.964 8.971 8.092 16.099 17.063 17.062C20.77 22.061 22 20.745 22 19.25v-4.116a1.25 1.25 0 0 0-.927-1.208l-3.69-.986a1.25 1.25 0 0 0-1.207.324l-1.911 1.91a14.37 14.37 0 0 1-5.44-5.439l1.911-1.91a1.25 1.25 0 0 0 .324-1.207l-.986-3.69A1.25 1.25 0 0 0 8.866 2zM3.602 4.667C3.538 4.066 4.035 3.5 4.75 3.5h3.924l.9 3.366-2.591 2.59.25.488a15.86 15.86 0 0 0 6.824 6.823l.486.25 2.591-2.59 3.366.899v3.924c0 .715-.566 1.212-1.167 1.148-8.27-.888-14.843-7.462-15.73-15.731M17 2.5v3.44l3.72-3.72 1.06 1.06L18.06 7h3.44v1.5h-4.75c-.69 0-1.25-.56-1.25-1.25V2.5zm-3 3V10h4.5v1.5h-4.75c-.69 0-1.25-.56-1.25-1.25V5.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconIncoming_forwarding;
