import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconContact = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M20.75 4H3.25C2.56 4 2 4.56 2 5.25v13.5c0 .69.56 1.25 1.25 1.25h17.5c.69 0 1.25-.56 1.25-1.25V5.25C22 4.56 21.44 4 20.75 4m-12 3a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5M5 17v-.755A2.754 2.754 0 0 1 7.75 13.5h1.995a2.75 2.75 0 0 1 2.75 2.745v.75h-7.5zm12.5-2.5h-4V13h4zM19 11h-5.5V9.5H19z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconContact;
