import React from 'react';
import type { DuotoneIconBaseProps, IconBaseProps } from '../types';
import type { IconNames, IconVariants } from './iconNames';
import { useTouchDevice } from '../hooks/useTouchDevice';

import LineNeoIconAdd from './line/LineNeoIconAdd';
import LineNeoIconAdministration from './line/LineNeoIconAdministration';
import LineNeoIconAnnouncement from './line/LineNeoIconAnnouncement';
import LineNeoIconAnonymous from './line/LineNeoIconAnonymous';
import LineNeoIconApi_clients from './line/LineNeoIconApi_clients';
import LineNeoIconArrowLeftUp from './line/LineNeoIconArrowLeftUp';
import LineNeoIconArrowRight from './line/LineNeoIconArrowRight';
import LineNeoIconArrow_down_left_circle from './line/LineNeoIconArrow_down_left_circle';
import LineNeoIconBack from './line/LineNeoIconBack';
import LineNeoIconCalendar from './line/LineNeoIconCalendar';
import LineNeoIconCall from './line/LineNeoIconCall';
import LineNeoIconCall_queue from './line/LineNeoIconCall_queue';
import LineNeoIconCheck from './line/LineNeoIconCheck';
import LineNeoIconCheck_circle from './line/LineNeoIconCheck_circle';
import LineNeoIconClose from './line/LineNeoIconClose';
import LineNeoIconConference_room from './line/LineNeoIconConference_room';
import LineNeoIconContact from './line/LineNeoIconContact';
import LineNeoIconCopy from './line/LineNeoIconCopy';
import LineNeoIconCross_circle from './line/LineNeoIconCross_circle';
import LineNeoIconCustomization from './line/LineNeoIconCustomization';
import LineNeoIconDelete from './line/LineNeoIconDelete';
import LineNeoIconDevice_pool from './line/LineNeoIconDevice_pool';
import LineNeoIconDown from './line/LineNeoIconDown';
import LineNeoIconDownload from './line/LineNeoIconDownload';
import LineNeoIconDrag_indicator from './line/LineNeoIconDrag_indicator';
import LineNeoIconESim from './line/LineNeoIconESim';
import LineNeoIconEdit from './line/LineNeoIconEdit';
import LineNeoIconEmail from './line/LineNeoIconEmail';
import LineNeoIconEmpty from './line/LineNeoIconEmpty';
import LineNeoIconEnterprise from './line/LineNeoIconEnterprise';
import LineNeoIconEventList from './line/LineNeoIconEventList';
import LineNeoIconExternal_link from './line/LineNeoIconExternal_link';
import LineNeoIconFax from './line/LineNeoIconFax';
import LineNeoIconFile from './line/LineNeoIconFile';
import LineNeoIconGreeting from './line/LineNeoIconGreeting';
import LineNeoIconGroup from './line/LineNeoIconGroup';
import LineNeoIconHelp_circle from './line/LineNeoIconHelp_circle';
import LineNeoIconHide from './line/LineNeoIconHide';
import LineNeoIconHide_navigation from './line/LineNeoIconHide_navigation';
import LineNeoIconHourglass from './line/LineNeoIconHourglass';
import LineNeoIconHouse from './line/LineNeoIconHouse';
import LineNeoIconIncoming_forwarding from './line/LineNeoIconIncoming_forwarding';
import LineNeoIconInfo from './line/LineNeoIconInfo';
import LineNeoIconInput_device from './line/LineNeoIconInput_device';
import LineNeoIconIntegrations from './line/LineNeoIconIntegrations';
import LineNeoIconInternational_phone_number from './line/LineNeoIconInternational_phone_number';
import LineNeoIconInvoice from './line/LineNeoIconInvoice';
import LineNeoIconIvr from './line/LineNeoIconIvr';
import LineNeoIconLaptop from './line/LineNeoIconLaptop';
import LineNeoIconLocale_phone_number from './line/LineNeoIconLocale_phone_number';
import LineNeoIconLocation from './line/LineNeoIconLocation';
import LineNeoIconMobileTelephony from './line/LineNeoIconMobileTelephony';
import LineNeoIconMobile_network from './line/LineNeoIconMobile_network';
import LineNeoIconMore from './line/LineNeoIconMore';
import LineNeoIconMulti_user_channel from './line/LineNeoIconMulti_user_channel';
import LineNeoIconNext from './line/LineNeoIconNext';
import LineNeoIconNq_nupsi from './line/LineNeoIconNq_nupsi';
import LineNeoIconNumber from './line/LineNeoIconNumber';
import LineNeoIconNumber_extension from './line/LineNeoIconNumber_extension';
import LineNeoIconOriginal_size from './line/LineNeoIconOriginal_size';
import LineNeoIconOutgoing_forwarding from './line/LineNeoIconOutgoing_forwarding';
import LineNeoIconOutput_device from './line/LineNeoIconOutput_device';
import LineNeoIconPartner_program from './line/LineNeoIconPartner_program';
import LineNeoIconPause_circle from './line/LineNeoIconPause_circle';
import LineNeoIconPbx from './line/LineNeoIconPbx';
import LineNeoIconPending from './line/LineNeoIconPending';
import LineNeoIconPercentage_circle from './line/LineNeoIconPercentage_circle';
import LineNeoIconPhone from './line/LineNeoIconPhone';
import LineNeoIconPhone_number from './line/LineNeoIconPhone_number';
import LineNeoIconPlay_circle from './line/LineNeoIconPlay_circle';
import LineNeoIconPlug_and_play from './line/LineNeoIconPlug_and_play';
import LineNeoIconPlus_circle from './line/LineNeoIconPlus_circle';
import LineNeoIconPresence from './line/LineNeoIconPresence';
import LineNeoIconProducts from './line/LineNeoIconProducts';
import LineNeoIconRefresh from './line/LineNeoIconRefresh';
import LineNeoIconRinging_order_fixed from './line/LineNeoIconRinging_order_fixed';
import LineNeoIconRinging_order_longest_idle from './line/LineNeoIconRinging_order_longest_idle';
import LineNeoIconRinging_order_prarallel from './line/LineNeoIconRinging_order_prarallel';
import LineNeoIconRocket from './line/LineNeoIconRocket';
import LineNeoIconRouting from './line/LineNeoIconRouting';
import LineNeoIconSearch from './line/LineNeoIconSearch';
import LineNeoIconSheep from './line/LineNeoIconSheep';
import LineNeoIconShow from './line/LineNeoIconShow';
import LineNeoIconShow_navigation from './line/LineNeoIconShow_navigation';
import LineNeoIconSign_out from './line/LineNeoIconSign_out';
import LineNeoIconSimCard from './line/LineNeoIconSimCard';
import LineNeoIconSipgate_io from './line/LineNeoIconSipgate_io';
import LineNeoIconSmart_watch from './line/LineNeoIconSmart_watch';
import LineNeoIconSso from './line/LineNeoIconSso';
import LineNeoIconTablet from './line/LineNeoIconTablet';
import LineNeoIconTimer from './line/LineNeoIconTimer';
import LineNeoIconTimetable from './line/LineNeoIconTimetable';
import LineNeoIconTrunk from './line/LineNeoIconTrunk';
import LineNeoIconUp from './line/LineNeoIconUp';
import LineNeoIconUpload from './line/LineNeoIconUpload';
import LineNeoIconUserSettings from './line/LineNeoIconUserSettings';
import LineNeoIconUser from './line/LineNeoIconUser';
import LineNeoIconUser_settings from './line/LineNeoIconUser_settings';
import LineNeoIconVoicemail from './line/LineNeoIconVoicemail';
import LineNeoIconVoip_phone from './line/LineNeoIconVoip_phone';
import LineNeoIconWarning from './line/LineNeoIconWarning';
import LineNeoIconWebphone from './line/LineNeoIconWebphone';
import LineNeoIconZoom_in from './line/LineNeoIconZoom_in';
import LineNeoIconZoom_out from './line/LineNeoIconZoom_out';
import LineNeoIconZoom_reset from './line/LineNeoIconZoom_reset';
import SolidNeoIconAdministration from './solid/SolidNeoIconAdministration';
import SolidNeoIconCollapse from './solid/SolidNeoIconCollapse';
import SolidNeoIconContact from './solid/SolidNeoIconContact';
import SolidNeoIconDevice_pool from './solid/SolidNeoIconDevice_pool';
import SolidNeoIconEventList from './solid/SolidNeoIconEventList';
import SolidNeoIconExpand from './solid/SolidNeoIconExpand';
import SolidNeoIconFax from './solid/SolidNeoIconFax';
import SolidNeoIconForm_error from './solid/SolidNeoIconForm_error';
import SolidNeoIconHouse from './solid/SolidNeoIconHouse';
import SolidNeoIconMobileTelephony from './solid/SolidNeoIconMobileTelephony';
import SolidNeoIconMs_teams from './solid/SolidNeoIconMs_teams';
import SolidNeoIconMulti_user_channel from './solid/SolidNeoIconMulti_user_channel';
import SolidNeoIconPbx from './solid/SolidNeoIconPbx';
import SolidNeoIconPresence from './solid/SolidNeoIconPresence';
import SolidNeoIconProducts from './solid/SolidNeoIconProducts';
import SolidNeoIconRouting from './solid/SolidNeoIconRouting';
import SolidNeoIconSheep from './solid/SolidNeoIconSheep';
import SolidNeoIconUserSettings from './solid/SolidNeoIconUserSettings';
import SolidNeoIconUser_settings from './solid/SolidNeoIconUser_settings';
import DuotoneNeoIconAnnouncement from './duotone/DuotoneNeoIconAnnouncement';
import DuotoneNeoIconCalendar from './duotone/DuotoneNeoIconCalendar';
import DuotoneNeoIconCall_cancel from './duotone/DuotoneNeoIconCall_cancel';
import DuotoneNeoIconCall_queue from './duotone/DuotoneNeoIconCall_queue';
import DuotoneNeoIconContact from './duotone/DuotoneNeoIconContact';
import DuotoneNeoIconGreeting from './duotone/DuotoneNeoIconGreeting';
import DuotoneNeoIconIncoming_forwarding from './duotone/DuotoneNeoIconIncoming_forwarding';
import DuotoneNeoIconIvr from './duotone/DuotoneNeoIconIvr';
import DuotoneNeoIconMulti_user_channel from './duotone/DuotoneNeoIconMulti_user_channel';
import DuotoneNeoIconNumber from './duotone/DuotoneNeoIconNumber';
import DuotoneNeoIconNumber_extension from './duotone/DuotoneNeoIconNumber_extension';
import DuotoneNeoIconOutgoing_forwarding from './duotone/DuotoneNeoIconOutgoing_forwarding';
import DuotoneNeoIconSheep from './duotone/DuotoneNeoIconSheep';
import DuotoneNeoIconTimetable from './duotone/DuotoneNeoIconTimetable';
import DuotoneNeoIconUser from './duotone/DuotoneNeoIconUser';
import DuotoneNeoIconVoicemail from './duotone/DuotoneNeoIconVoicemail';

const iconComponents = {
	'line.Add': LineNeoIconAdd,
	'line.Administration': LineNeoIconAdministration,
	'line.Announcement': LineNeoIconAnnouncement,
	'line.Anonymous': LineNeoIconAnonymous,
	'line.Api_clients': LineNeoIconApi_clients,
	'line.ArrowLeftUp': LineNeoIconArrowLeftUp,
	'line.ArrowRight': LineNeoIconArrowRight,
	'line.Arrow_down_left_circle': LineNeoIconArrow_down_left_circle,
	'line.Back': LineNeoIconBack,
	'line.Calendar': LineNeoIconCalendar,
	'line.Call': LineNeoIconCall,
	'line.Call_queue': LineNeoIconCall_queue,
	'line.Check': LineNeoIconCheck,
	'line.Check_circle': LineNeoIconCheck_circle,
	'line.Close': LineNeoIconClose,
	'line.Conference_room': LineNeoIconConference_room,
	'line.Contact': LineNeoIconContact,
	'line.Copy': LineNeoIconCopy,
	'line.Cross_circle': LineNeoIconCross_circle,
	'line.Customization': LineNeoIconCustomization,
	'line.Delete': LineNeoIconDelete,
	'line.Device_pool': LineNeoIconDevice_pool,
	'line.Down': LineNeoIconDown,
	'line.Download': LineNeoIconDownload,
	'line.Drag_indicator': LineNeoIconDrag_indicator,
	'line.ESim': LineNeoIconESim,
	'line.Edit': LineNeoIconEdit,
	'line.Email': LineNeoIconEmail,
	'line.Empty': LineNeoIconEmpty,
	'line.Enterprise': LineNeoIconEnterprise,
	'line.EventList': LineNeoIconEventList,
	'line.External_link': LineNeoIconExternal_link,
	'line.Fax': LineNeoIconFax,
	'line.File': LineNeoIconFile,
	'line.Greeting': LineNeoIconGreeting,
	'line.Group': LineNeoIconGroup,
	'line.Help_circle': LineNeoIconHelp_circle,
	'line.Hide': LineNeoIconHide,
	'line.Hide_navigation': LineNeoIconHide_navigation,
	'line.Hourglass': LineNeoIconHourglass,
	'line.House': LineNeoIconHouse,
	'line.Incoming_forwarding': LineNeoIconIncoming_forwarding,
	'line.Info': LineNeoIconInfo,
	'line.Input_device': LineNeoIconInput_device,
	'line.Integrations': LineNeoIconIntegrations,
	'line.International_phone_number': LineNeoIconInternational_phone_number,
	'line.Invoice': LineNeoIconInvoice,
	'line.Ivr': LineNeoIconIvr,
	'line.Laptop': LineNeoIconLaptop,
	'line.Locale_phone_number': LineNeoIconLocale_phone_number,
	'line.Location': LineNeoIconLocation,
	'line.MobileTelephony': LineNeoIconMobileTelephony,
	'line.Mobile_network': LineNeoIconMobile_network,
	'line.More': LineNeoIconMore,
	'line.Multi_user_channel': LineNeoIconMulti_user_channel,
	'line.Next': LineNeoIconNext,
	'line.Nq_nupsi': LineNeoIconNq_nupsi,
	'line.Number': LineNeoIconNumber,
	'line.Number_extension': LineNeoIconNumber_extension,
	'line.Original_size': LineNeoIconOriginal_size,
	'line.Outgoing_forwarding': LineNeoIconOutgoing_forwarding,
	'line.Output_device': LineNeoIconOutput_device,
	'line.Partner_program': LineNeoIconPartner_program,
	'line.Pause_circle': LineNeoIconPause_circle,
	'line.Pbx': LineNeoIconPbx,
	'line.Pending': LineNeoIconPending,
	'line.Percentage_circle': LineNeoIconPercentage_circle,
	'line.Phone': LineNeoIconPhone,
	'line.Phone_number': LineNeoIconPhone_number,
	'line.Play_circle': LineNeoIconPlay_circle,
	'line.Plug_and_play': LineNeoIconPlug_and_play,
	'line.Plus_circle': LineNeoIconPlus_circle,
	'line.Presence': LineNeoIconPresence,
	'line.Products': LineNeoIconProducts,
	'line.Refresh': LineNeoIconRefresh,
	'line.Ringing_order_fixed': LineNeoIconRinging_order_fixed,
	'line.Ringing_order_longest_idle': LineNeoIconRinging_order_longest_idle,
	'line.Ringing_order_prarallel': LineNeoIconRinging_order_prarallel,
	'line.Rocket': LineNeoIconRocket,
	'line.Routing': LineNeoIconRouting,
	'line.Search': LineNeoIconSearch,
	'line.Sheep': LineNeoIconSheep,
	'line.Show': LineNeoIconShow,
	'line.Show_navigation': LineNeoIconShow_navigation,
	'line.Sign_out': LineNeoIconSign_out,
	'line.SimCard': LineNeoIconSimCard,
	'line.Sipgate_io': LineNeoIconSipgate_io,
	'line.Smart_watch': LineNeoIconSmart_watch,
	'line.Sso': LineNeoIconSso,
	'line.Tablet': LineNeoIconTablet,
	'line.Timer': LineNeoIconTimer,
	'line.Timetable': LineNeoIconTimetable,
	'line.Trunk': LineNeoIconTrunk,
	'line.Up': LineNeoIconUp,
	'line.Upload': LineNeoIconUpload,
	'line.UserSettings': LineNeoIconUserSettings,
	'line.User': LineNeoIconUser,
	'line.User_settings': LineNeoIconUser_settings,
	'line.Voicemail': LineNeoIconVoicemail,
	'line.Voip_phone': LineNeoIconVoip_phone,
	'line.Warning': LineNeoIconWarning,
	'line.Webphone': LineNeoIconWebphone,
	'line.Zoom_in': LineNeoIconZoom_in,
	'line.Zoom_out': LineNeoIconZoom_out,
	'line.Zoom_reset': LineNeoIconZoom_reset,
	'solid.Administration': SolidNeoIconAdministration,
	'solid.Collapse': SolidNeoIconCollapse,
	'solid.Contact': SolidNeoIconContact,
	'solid.Device_pool': SolidNeoIconDevice_pool,
	'solid.EventList': SolidNeoIconEventList,
	'solid.Expand': SolidNeoIconExpand,
	'solid.Fax': SolidNeoIconFax,
	'solid.Form_error': SolidNeoIconForm_error,
	'solid.House': SolidNeoIconHouse,
	'solid.MobileTelephony': SolidNeoIconMobileTelephony,
	'solid.Ms_teams': SolidNeoIconMs_teams,
	'solid.Multi_user_channel': SolidNeoIconMulti_user_channel,
	'solid.Pbx': SolidNeoIconPbx,
	'solid.Presence': SolidNeoIconPresence,
	'solid.Products': SolidNeoIconProducts,
	'solid.Routing': SolidNeoIconRouting,
	'solid.Sheep': SolidNeoIconSheep,
	'solid.UserSettings': SolidNeoIconUserSettings,
	'solid.User_settings': SolidNeoIconUser_settings,
	'duotone.Announcement': DuotoneNeoIconAnnouncement,
	'duotone.Calendar': DuotoneNeoIconCalendar,
	'duotone.Call_cancel': DuotoneNeoIconCall_cancel,
	'duotone.Call_queue': DuotoneNeoIconCall_queue,
	'duotone.Contact': DuotoneNeoIconContact,
	'duotone.Greeting': DuotoneNeoIconGreeting,
	'duotone.Incoming_forwarding': DuotoneNeoIconIncoming_forwarding,
	'duotone.Ivr': DuotoneNeoIconIvr,
	'duotone.Multi_user_channel': DuotoneNeoIconMulti_user_channel,
	'duotone.Number': DuotoneNeoIconNumber,
	'duotone.Number_extension': DuotoneNeoIconNumber_extension,
	'duotone.Outgoing_forwarding': DuotoneNeoIconOutgoing_forwarding,
	'duotone.Sheep': DuotoneNeoIconSheep,
	'duotone.Timetable': DuotoneNeoIconTimetable,
	'duotone.User': DuotoneNeoIconUser,
	'duotone.Voicemail': DuotoneNeoIconVoicemail} as const;

type IconComponentsType = typeof iconComponents;

// Full props including variant and name
type IconProps<N extends IconNames> = {
	name: N;
	variant: IconVariants<N>;
} & ({ variant: 'duotone' } extends { variant: IconVariants<N> } // If the variant is 'duotone', include DuotoneIconBaseProps, otherwise IconBaseProps
	?
			| ({ variant: 'duotone' } & DuotoneIconBaseProps)
			| ({ variant: Exclude<IconVariants<N>, 'duotone'> } & IconBaseProps)
	: IconBaseProps);

export function NeoIcon<N extends IconNames>({
	name,
	variant,
	size = 24,
	touchSize,
	...props
}: IconProps<N>): React.ReactElement | null {
	const isTouchDevice = useTouchDevice();
	const key = `${variant}.${name}`;
	const Icon = iconComponents[key as keyof IconComponentsType];
	if (!Icon) return null;

	const iconSize = isTouchDevice && touchSize ? touchSize : size;

	if (variant === 'duotone') {
		// Since TypeScript can't narrow the type based on the runtime value of variant,
		// we need to handle the case where color2 might be missing
		const propsWithColor2 = { color2: '', ...props };
		const { color2, ...restProps } = propsWithColor2;
		return <Icon size={iconSize} color2={color2} {...restProps} />;
	}
	return <Icon size={iconSize} color2='' {...props} />;
}
