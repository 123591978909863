import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconProducts = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M13 3h6.75c.69 0 1.25.56 1.25 1.25V11h-8zm1.5 6.5h5v-5h-5zM4.25 21C3.56 21 3 20.44 3 19.75V13h8v8zm5.25-6.5h-5v5h5zM3 4.25V11h8V3H4.25C3.56 3 3 3.56 3 4.25m1.5.25h5v5h-5zM16.25 13h1.5v3.25H21v1.5h-3.25V21h-1.5v-3.25H13v-1.5h3.25z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconProducts;
