import * as React from 'react';
import type { DuotoneIconBaseProps } from '../../types';
const DuotoneNeoIconContact = ({
	size = 24,
	...props
}: DuotoneIconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path fill={props.color2} d="M3 5h18v14H3z" />
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2 5.25C2 4.56 2.56 4 3.25 4h17.5c.69 0 1.25.56 1.25 1.25v13.5c0 .69-.56 1.25-1.25 1.25H3.25C2.56 20 2 19.44 2 18.75zm1.5.25v13h17v-13zm5.25 3a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5M6 9.75a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0m.501 6.498A1.25 1.25 0 0 1 7.751 15H9.75c.69 0 1.248.558 1.25 1.248L11 17 12.5 17l-.001-.754a2.75 2.75 0 0 0-2.75-2.745H7.75a2.75 2.75 0 0 0-2.75 2.745L5 16.999 6.5 17zM19 11h-5.5V9.5H19zm-5.5 3.5h4V13h-4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default DuotoneNeoIconContact;
